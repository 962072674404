import { FragmentOf } from 'gql.tada'
import { useMemo } from 'react'

import { CmsMediaFragment } from '@/app/_graphql/media.js'

export type MediaFragmentType = FragmentOf<typeof CmsMediaFragment>
export type MediaFragmentSize = keyof NonNullable<MediaFragmentType['sizes']>

export const getMediaSizeUrlWithFallback = (
  media: MediaFragmentType | null | undefined,
  size: MediaFragmentSize,
): string | undefined => {
  if (!media) {
    return undefined
  }
  return media.sizes?.[size]?.url ?? media.url ?? undefined
}

type Size = 'small' | 'medium' | 'large'
type ReducerOutputItem<T> = {
  media: T
  size: Size
}
export type UseResponsiveMediaProp<T> = {
  media: T
  size: Size | null
}[]

export const useResponsiveMedia = <T>(
  responsiveMedia: UseResponsiveMediaProp<T>,
): { smallMedia: T | undefined; mediumMedia: T | undefined; largeMedia: T | undefined } => {
  const mediaItems = useMemo(
    () =>
      responsiveMedia.reduce<{
        small: ReducerOutputItem<T> | null
        medium: ReducerOutputItem<T> | null
        large: ReducerOutputItem<T> | null
      }>(
        (imagesAcc, value) => {
          if (value.size) {
            imagesAcc[value.size] = value as ReducerOutputItem<T>
          }
          return imagesAcc
        },
        {
          small: null,
          medium: null,
          large: null,
        },
      ),
    [responsiveMedia],
  )

  // fallback to the next smallest value if none is provided for that breakpoint
  const smallMedia = mediaItems.small?.media
  const mediumMedia = mediaItems.medium?.media ?? smallMedia
  const largeMedia = mediaItems.large?.media ?? mediumMedia ?? smallMedia

  return {
    smallMedia,
    mediumMedia,
    largeMedia,
  }
}
