type OverlayGradientProps = {
  mobile?: boolean
  className?: string
}

export const OverlayGradient = ({ mobile, className }: OverlayGradientProps) => {
  if (mobile) {
    return (
      <div className={`Gradient pointer-events-none absolute inset-0 size-full mix-blend-multiply ${className}`}>
        <svg
          className="size-full"
          preserveAspectRatio="none"
          viewBox="0 0 390 636"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="390" height="636" fill="white" />
          <rect width="390" height="636" fill="url(#paint0_linear_8247_33397)" className="mix-blend-multiply" />
          <defs>
            <linearGradient
              id="paint0_linear_8247_33397"
              x1="195"
              y1="0"
              x2="195"
              y2="636"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopOpacity="0.4" />
              <stop offset="0.138034" stopOpacity="0.1" />
              <stop offset="0.367931" stopOpacity="0.5" />
              <stop offset="1" stopOpacity="0.5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    )
  }

  return (
    <div className={`Gradient pointer-events-none absolute inset-0 size-full mix-blend-multiply ${className}`}>
      <svg
        className="size-full"
        preserveAspectRatio="none"
        viewBox="0 0 1920 1080"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="1920" height="1080" fill="white" />
        <rect width="1920" height="1080" fill="url(#paint0_linear_8239_31283)" className="mix-blend-multiply" />
        <defs>
          <linearGradient
            id="paint0_linear_8239_31283"
            x1="960"
            y1="0"
            x2="960"
            y2="1080"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity="0.4" />
            <stop offset="0.138034" stopOpacity="0.1" />
            <stop offset="0.5" stopOpacity="0.1" />
            <stop offset="1" stopOpacity="0.5" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}
