import { type MetaDescriptor } from '@remix-run/react'

type ImageType = { url: string | null; width: number | null; height: number | null; mimeType: string | null }

const titleTags = (title: string) => (title ? [{ title }, { property: 'og:title', content: title }] : [])
const descriptionTags = (description: string | null | undefined) =>
  description
    ? [
        { name: 'description', content: description },
        { name: 'og:description', content: description },
      ]
    : []
const imageTags = (image: ImageType | undefined | null) =>
  image?.url
    ? [
        { name: 'og:image', content: image.url },
        { name: 'og:image:width', content: image.width },
        { name: 'og:image:height', content: image.height },
        { name: 'og:image:type', content: image.mimeType },
      ]
    : []

export const generateMeta = (
  meta: {
    title: string | null
    description: string | null
    noIndex: boolean | null
    jsonLD?: unknown
    image: ImageType | null
  } | null,
): MetaDescriptor[] => {
  return [
    ...titleTags(meta?.title || 'Nav'),
    ...descriptionTags(meta?.description),
    {
      name: 'robots',
      content: meta?.noIndex ? 'noindex' : 'index',
    },
    ...(meta?.jsonLD && Object.keys(meta.jsonLD).length
      ? [
          {
            'script:ld+json': meta.jsonLD,
          },
        ]
      : []),
    ...imageTags(meta?.image),
  ]
}
